import React, { useEffect, useState } from "react";
import { File } from "../../api";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Upload } from "antd";
import { useParams } from "react-router";
import HtmlParser from "react-html-parser";
import { addCommentsDraftAgreement, commentsFileUpload, getCommentsDraftAgreement } from "../../api/currentApplications";
import { useUser } from "../../hooks/useUser";
import { socket } from "../../utils/socket-io";

export default function DraftCommentComments(props) {
  const [load, setLoad] = useState(false);
  const [file, setFile] = useState({});

  const user = useUser() || {};
  const { roleName = "" } = user;
  const [editorState, setEditorState] = useState(" ");
  const [commentsText, setCommentsText] = useState(" ");
  const [allComments, setAllComments] = useState([]);

  let params = useParams();
  let { license_fee, fileNameOriginal, fileNameUpdated, stage, supporting_doc_value, resolved, resolveComment } = props || {};

  useEffect(() => {
    const fetchData = async (applicationId) => {
      socket.emit("chat send");
      socket.on("chat send", (msg) => {
        setAllComments((prev) => (prev ? [...prev, msg] : [msg] || []));
      });
      let responseComment = await getCommentsDraftAgreement(applicationId);

      responseComment.data.data.draft.map((value) => (value.action === "Upload and Send to NCRR" ? setAllComments(value.comment ? value.comment : []) : null));
      return () => {
        setAllComments([]);
      };
    };
    fetchData(params.applicationId);
  }, [params.applicationId]);

  let valueprops = {
    action: "",
    onChange(info) {
      const formData = new FormData();
      formData.append("applicationID", params.applicationId);
      if (info && info.fileList && info.fileList.length > 0) {
        info.fileList.map((fileData) => formData.append("file", fileData.originFileObj));
      }

      commentsFileUpload(formData).then((res) => setFile(res.data.data));
    },
    multiple: true,
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const putComment = async (e) => {
    e.preventDefault();

    if (commentsText.length) {
      setEditorState(" ");
      setCommentsText(" ");
      const date = new Date();
      const formData = {
        id: user.id,
        name: user.contact.name,
        role: user.role.role || user.roleName,
        email: user.contact.email,
        roleName: user.roleName,
      };
      const data = {
        agreementType: "draft",
        comment: commentsText,
        timeStamp: date.toDateString(),
        from: JSON.stringify(formData),
        applicationID: params.applicationId,
        files: file,
        action: "Upload and Send to NCRR",
      };
      socket.emit("chat message", data);
      let comm = await addCommentsDraftAgreement(data);
      if (comm) {
        setEditorState(" ");
        setCommentsText(" ");

        setLoad(false);
      }
    }
    setEditorState("");
    setCommentsText("");
  };
  const addRefresh = () => {
    window.location.reload();
  };

  const handleComment = (data) => {
    setCommentsText(data);
  };

  return (
    <div>
      {load && (
        <div className="d-flex align-items-center justify-content-center  display-2 file-upload-loader">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      )}

      <div
        className="modal fade comment-modal hide"
        id="comment-modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered mx-h-100 modal-dialog-scrollable">
          <div className="modal-content h-100">
            <div className="modal-body p-0">
              <div className="container-fluid h-100 p-0">
                <div className="row h-100 m-0">
                  <div className="col-md-5 h-100 p-0">
                    <div className="card-header px-5">
                      <h3 className="m-0">Agreement Generated</h3>
                    </div>
                    <div className="card  border-0">
                      <div className="card-body">
                        <div className="container-fluid p-0">
                          <div className="row">
                            <div className="card-body">
                              <div className="mb-3">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                  <label className="text-muted d-block">Uploaded Agreement</label>
                                </div>
                                <div></div>
                                <div className="card bg-secondary border-0">
                                  <div className="card-body p-3">
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <i className="fal fa-file-pdf"></i>
                                      </div>
                                      <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal}</div>
                                      <div className="flex-shrink-0">
                                        <a target="_blank" href={File + fileNameUpdated} className="btn btn-link">
                                          View
                                        </a>
                                        <a href={File + fileNameUpdated} target="_blank" className="btn btn-link">
                                          Download
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                  <label className="text-muted d-block">Supporting Document</label>
                                </div>
                                {supporting_doc_value.fileDataMultiple && supporting_doc_value.fileDataMultiple.length > 0 ? (
                                  <>
                                    {supporting_doc_value.fileDataMultiple &&
                                      supporting_doc_value.fileDataMultiple.map((supporting_doc_value, ind) => (
                                        <div className="card-body p-3" key={ind}>
                                          <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                              <i className="fal fa-file-pdf"></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3 w-50">{supporting_doc_value.fileNameOriginal ? supporting_doc_value.fileNameOriginal : fileNameOriginal}</div>
                                            <div className="flex-shrink-0">
                                              <a href={File + (supporting_doc_value.fileNameUpdated ? supporting_doc_value.fileNameUpdated : null)} target="_blank" className="btn btn-link">
                                                View
                                              </a>
                                              <a href={File + (supporting_doc_value.fileNameUpdated ? supporting_doc_value.fileNameUpdated : null)} target="_blank" className="btn btn-link">
                                                Download
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      ))}{" "}
                                  </>
                                ) : (
                                  <p className="m-4 h5">No Supporting Documents</p>
                                )}
                              </div>
                              <div className="mb-3">
                                <label className="text-muted d-block">Entered License Fee</label>
                                <div className="card bg-secondary border-0">
                                  <div className="card-body p-3">
                                    <div className="d-flex align-items-center">
                                      <div className="flex-grow-1">${license_fee}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 h-100 bg-secondary p-0">
                    <div className="row msg-box m-0  h-100">
                      <div className="col-md-12 p-0">
                        <div className="collapse show  h-100" id="comment2">
                          <div className="card border-0 rounded-0 chat-window bg-secondary  h-100">
                            <div className="tab-col">
                              <ul className="nav nav-tabs justify-content-between mb-5" id="myTab" role="tablist">
                                <li className="nav-item col px-0" role="presentation">
                                  <button className="nav-link active" id="team-tab" data-bs-toggle="tab" data-bs-target="#team" type="button" role="tab" aria-controls="team" aria-selected="true">
                                    New Comments
                                  </button>
                                </li>
                              </ul>

                              <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="team" role="tabpanel" aria-labelledby="team-tab">
                                  <div className="card-body h-100 overflow-auto pt-0">
                                    <div className="panel-body msg_container_base container-fluid">
                                      {allComments &&
                                        allComments.map((comme) => (
                                          <div className={`row msg_container ${JSON.parse(comme.from).role === roleName ? "base_sent justify-content-end" : "base_receive"} `}>
                                            <div className="col-md-8">
                                              <div className="d-flex justify-content-end">
                                                <div className="messages">
                                                  {HtmlParser(comme.comment)}
                                                  {comme &&
                                                    comme.files &&
                                                    Symbol.iterator in Object(comme.files) &&
                                                    comme.files.map((rr) => (
                                                      <div className="row align-items-center mb-4">
                                                        <div className="col-7">
                                                          <div className="d-flex align-items-center">
                                                            <i className="fal fa-file-pdf"></i>
                                                            <p className="text-break ms-3 mb-0 ">{rr.originalName}</p>
                                                          </div>
                                                        </div>
                                                        <div className="col-5">
                                                          <a href={File + rr.fileNameUpdated} target="_blank" className="btn btn-sm m-0 btn-link">
                                                            View
                                                          </a>
                                                          <a href={File + rr.fileNameUpdated} target="_blank" className="btn btn-sm m-0 btn-link">
                                                            Download
                                                          </a>
                                                        </div>
                                                      </div>
                                                    ))}
                                                  <time className="text-muted">
                                                    {comme.timeStamp} {JSON.parse(comme.from).roleName}
                                                  </time>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {stage >= 12 ? null : (
                              <div className="card-footer bg-secondary p-0">
                                <div className="position-relative w-100 border">
                                  <div className="">
                                    <div
                                      //onSubmit={()=>{addComment()}}
                                      className="d-flex flex-column custom-editor"
                                    >
                                      <CKEditor
                                        config={{
                                          toolbar: ["bold", "italic", "strike"],
                                        }}
                                        editor={ClassicEditor}
                                        data={editorState}
                                        style={{
                                          "margin-top": "100px",
                                          border: "5px solid red",
                                        }}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();

                                          handleComment(data);
                                        }}
                                      />

                                      <button className="btn bg-primary" onClick={putComment}>
                                        <div className="send">
                                          <span className="text-white shadow">Add Comment</span>
                                        </div>
                                      </button>
                                    </div>
                                    <div className="upload-col">
                                      <>
                                        <div>
                                          <div>
                                            <Upload customRequest={dummyRequest} accept=".pdf, .cad, .docx" {...valueprops} className="upload-list-inline" id="siofu_input" name="aggrement">
                                              <a className="upload-btn text-primary pl-1">
                                                <i className="fas fa-paperclip"></i>
                                              </a>
                                            </Upload>
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between border-top ">
              <div className="row w-100 m-0">
                <div className="col-md-5 p-0"></div>
                <div className="col-md-7 p-0 justify-content-between d-flex">
                  <div className={`${resolved === false ? "" : "col-md-3"}`}>
                    {roleName === "Approving Representative" && resolved === false && allComments.length > 0 ? (
                      <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        Resolve Comment
                      </button>
                    ) : null}
                    <>
                      {resolved === true && allComments.length > 0 ? (
                        <button type="button" className="btn btn-primary disabled" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                          <i className="far fa-check text-muted"></i> Resolved
                        </button>
                      ) : null}
                    </>
                  </div>
                  {resolved === false ? (
                    <div className=" col-md-7 p-0">
                      <span className="text-red h5">
                        Adding a comment here will not automatically send notification. Please make comment at once and click on Notify button after adding a comment.
                      </span>
                    </div>
                  ) : null}
                  <div className="col-md-2">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={addRefresh}>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="resolve1">
                Are Your Sure?
              </h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p className="text-center mb-0">This section is not reversible.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-primary" onClick={resolveComment} data-bs-dismiss="modal">
                Resolve
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
