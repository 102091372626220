/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getLicenseStatus, sendNotify } from "../../api/currentApplications";
import UploadDraftLoading from "../loaders/UploadDraftLoading";
import checkFileName from "../../utils/checkSpecialCharacter";
import { API_URL, File, REACT_APP_NS_risk_finance_fee_original } from "../../api";
import { Netsuite } from "../../schema/NetSuiteSchema";
import CompiledPayment from "../compiledPayment/CompiledPayment";
import { getNetsuiteInitialValue, netSuiteUpsertRecords, salesOrderValidate } from "../../api/Netsuite";
import FieldAll from "../ui/FieldAll";
import NetsuiteLink from "../ui/NetsuiteLink";
import { TriggerTransactionButton } from "../ui/TriggerTransaction";
import { useUser } from "../../hooks/useUser";
import { useApplication } from "../../hooks/useApplication";
export default function FinalRelease(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (d) => {
    if (!characterError.sealed_certification && !characterError.final_application_approval_letter) await props.finalRelease();
  };
  const [loading, setLoading] = useState(true);
  const [validateLoading, setValidateLoading] = useState(false);
  const [compileValue, setCompileValue] = useState([]);
  const [isSalesOrderValidated, setIsSalesOrderValidated] = useState(false);
  const [isSalesOrderValidatedTime, setIsSalesOrderValidatedTime] = useState(null);
  const [showAll, setShowAll] = React.useState([]);
  const [characterError, setCharacterError] = useState({
    sealed_certification: false,
    final_application_approval_letter: false,
    sealed_certificationName: "",
    final_application_approval_letterName: "",
  });
  const [riskFinanceFee, setReskFinanceFee] = useState(false);
  const [netsuiteField, setNetsuiteValue] = React.useState({
    AGREEMENT: {},
    SALESORDER: {},
  });
  const [records, setRecords] = React.useState([]);
  const [compileSignedValue, setCompileSignedValue] = useState([]);
  const [arrayOfnesuite, setArrayOfnesuite] = useState([]);
  const { roleName = "" } = useUser() || {};
  let { ItemId } = props;
  const application = useApplication();
  useEffect(() => {
    Netsuite.sections.forEach((section) => {
      section.questions.forEach((val) => {
        if (val.validatecompile === "true") {
          setArrayOfnesuite((initialValue) => [...initialValue, val]);
        }
      });
    });
  }, [Netsuite]);

  useEffect(() => {
    const array = arrayOfnesuite.map((section) => section?.recordName);
    setRecords(array);
  }, [arrayOfnesuite]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sortedRecords = records.sort((a, b) => a - b);
        const mergedResults = {};

        for (const record of sortedRecords) {
          const res = await getNetsuiteInitialValue(ItemId, record);
          const response = res?.data?.data;
          mergedResults[record] = response;
          setNetsuiteValue((initialValue) => ({
            ...initialValue,
            ...mergedResults,
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error as needed
      }
    };

    fetchData();
  }, [records, ItemId, getNetsuiteInitialValue]);

  const handleNetSuiteChange = (data, event, recordName, column) => {
    let item = 0;
    if (column?.itemId) {
      item = column.itemId;
    }
    const { keyName = "" } = data;
    const { value } = event.target;
    const acceptedType1 = ["text", "date", "number", "dropdown"];
    const acceptedType2 = ["textarea"];
    const type = data.type;
    if (acceptedType1.includes(type) && item === 0) {
      setNetsuiteValue((initialValue) => ({
        ...initialValue,
        [recordName]: { ...initialValue[recordName], [keyName]: value },
      }));
    }
    if (acceptedType2.includes(type) && item === 0) {
      if (recordName === "OPPORTUNITY" && keyName === "scopeofwork") {
        setNetsuiteValue((initialValue) => ({
          ...initialValue,
          OPPORTUNITY: { ...initialValue.OPPORTUNITY, scopeofwork: value },
          AGREEMENT: { ...initialValue.AGREEMENT, scopeofwork: value },
          UTILITY: { ...initialValue.UTILITY, wirescablesunits: value },
        }));
      } else {
        setNetsuiteValue((initialValue) => ({
          ...initialValue,
          [recordName]: { ...initialValue[recordName], [keyName]: value },
        }));
      }
    }
  };

  const handleNetSuiteClick = async (e) => {
    setValidateLoading(true);
    e.preventDefault();
    const { AGREEMENT, SALESORDER } = netsuiteField;
    await netSuiteUpsertRecords(ItemId, "AGREEMENT", AGREEMENT);
    await netSuiteUpsertRecords(ItemId, "SALESORDER", SALESORDER);
    await salesOrderValidate(ItemId);
    setIsSalesOrderValidated(true);
    setValidateLoading(false);
  };

  useEffect(async () => {
    let response = await getLicenseStatus(ItemId);
    setCompileValue(response.data.data);
    let value = response.data.data.compiled && response.data.data.compiled.filter((data) => data.action === "Sign and Paid");
    setReskFinanceFee(value[0].riskFinanceFee === "true" ? true : false);
    setLoading(false);
    const object = {
      applicationID: ItemId,
      signerEmail: ["Test"],
      agreementType: "CompiledAgreement",
    };
    let responses = await sendNotify(object);
    setCompileSignedValue(responses.data.responseData);
  }, []);
  useEffect(async () => {
    setIsSalesOrderValidated(application.salesorderValidated);
    setIsSalesOrderValidatedTime(application.salesorderValidatedTime);

    const { supplement = {} } = application;
    if (supplement.supplement) {
      setArrayOfnesuite((value) => {
        return value.map((each) => {
          if (each.recordName === "AGREEMENT") {
            return { ...each, hide: true };
          }
          return each;
        });
      });
    }
  }, []);
  useEffect(() => {
    setLoading(false);
  }, []);

  let { compiled, downloadSignedDocuments } = compileValue || [];
  let compiles0 = (compiled && compiled[0]) || [];
  let { action, date, formData } = compiles0 || {};
  let filedata = formData && formData[0];
  let { fileNameOriginal, fileNameUpdated, signedDocumentsEnvelopeId } = filedata || "";

  let compiles1 = (compiled && compiled[1]) || [];
  let { date: date1, formData: formData1 } = compiles1 || {};
  let filedata1 = formData1 && formData1[0];
  let filedata2 = formData1 && formData1[1];
  let filedata3 = formData1 && formData1[2];

  let { fileNameOriginal: fileNameOriginal1, fileNameUpdated: fileNameUpdated1 } = filedata1 || "";
  let {
    fileNameOriginal: fileNameOriginal2,
    // fileName: fileName2,
    fileNameUpdated: fileNameUpdated2,
  } = filedata2 || "";
  let { fileNameOriginal: fileNameOriginal3, fileName: fileName3, fileNameUpdated: fileNameUpdated3 } = filedata3 || "";

  let compiles2 = (compiled && compiled[2]) || [];
  let { action: action2, date: date2 } = compiles2 || {};
  let compiles3 = (compiled && compiled[3]) || [];
  let { action: action3, date: date3 } = compiles3 || {};
  let compiles4 = (compiled && compiled[4]) || [];

  let { action: action4, date: date4, formData: formData4 } = compiles4 || {};
  let filedata4 = formData4 && formData4[0];
  let filedata7 = formData4 && formData4[1];
  let { fileNameOriginal: fileNameOriginal4, fileName: fileName4, fileNameUpdated: fileNameUpdated4 } = filedata4 || "";
  let { fileNameOriginal: fileNameOriginal7, fileNameUpdated: fileNameUpdated7 } = filedata7 || "";

  let OperatingSigneeData = (compiled && compiled[5]) || [];
  let { action: OperatingSigneeaction, date: OperatingSigneedate } = OperatingSigneeData || {};

  let compiles5 = (compiled && (OperatingSigneeaction === "Send" ? compiled[5] : compiled[6])) || [];
  let { action: action5, formData: formData5 } = compiles5 || {};
  let filedata5 = (formData5 && formData5[0]) || {};

  let { formData: formData6, date: date6 } = compiles5;
  let formValuesD = (formData6 && formData6[1]) || [];
  let { fileNameOrginal: fileNameOrginal6, fileNameUpdated: fileNameUpdated6 } = formValuesD;
  let { fileNameOriginal: fileNameOriginal5, fileNameUpdated: fileNameUpdated5 } = filedata5 || "";

  let { draft } = compileValue || {};
  let DValue = draft && draft[0];

  let { licenseFee: Dform } = DValue || {};
  let lFee = Dform && Dform[0];
  let { license_fee } = lFee || "";

  let applicationIDfromURL = window.location.pathname.split("/");
  let applicationID = applicationIDfromURL[2] ? applicationIDfromURL[2] : null;

  const { name: SignerName } = compileSignedValue || {};
  const SignedDate = compileSignedValue ? (compileSignedValue.signedAt ? new Date(compileSignedValue.signedAt).toString().slice(0, 15) : "") : "";

  const validateAdte = isSalesOrderValidatedTime ? new Date(isSalesOrderValidatedTime).toString().slice(0, 15) : "";

  return loading ? (
    <>
      <UploadDraftLoading />
      <UploadDraftLoading />
    </>
  ) : (
    <div>
      <div className="container mt-4">
        <div className="row">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-lg-8 offset-lg-2">
              <div id="files-upload" className="">
                <div className="inner-form card mb-4">
                  <div className="card-header border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Compiled License Agreement</h2>
                      <small className="text-muted">By HNTB</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <label htmlFor="license-agreement" className="form-label">
                      Uploaded License Agreement
                    </label>
                    <div className="card bg-secondary border-0">
                      <div className="card-body p-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="fal fa-file-pdf"></i>
                          </div>
                          <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal}</div>
                          <div className="flex-shrink-0">
                            <a
                              href={File + (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 1 && !downloadSignedDocuments
                                  ? signedDocumentsEnvelopeId.length - 1 + "_"
                                  : signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length + "_") +
                                fileNameUpdated
                              }
                              target="_blank"
                              className="btn btn-link"
                            >
                              View
                            </a>
                            <a
                              href={File + (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 1 && !downloadSignedDocuments
                                  ? signedDocumentsEnvelopeId.length - 1 + "_"
                                  : signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length + "_") +
                                fileNameUpdated
                              }
                              target="_blank"
                              download
                              className="btn btn-link"
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer py-0 justify-content-center bg-success">
                    <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          <i className="fal fa-check"></i> {action}{" "}
                        </span>
                        <span>
                          By HNTB Representative on <small> {date ? date.slice(0, 15) : ""}</small>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>

                <div className="card mb-4 comment-card">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Uploaded Insurance</h2>
                      <small className="text-muted">By Applicant</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label className="text-muted d-block">Uploaded Certificates of Insurance</label>
                      <div className="card bg-secondary border-0 mb-2">
                        <div className="card-body">
                          <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <i className="fal fa-file-pdf"></i>
                            </div>
                            <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal1}</div>
                            <div className="flex-shrink-0">
                              <a href={File + fileNameUpdated1} target="_blank" className="btn btn-link">
                                View
                              </a>
                              <a href={File + fileNameUpdated1} target="_blank" download className="btn btn-link">
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-secondary border-0 mb-2">
                        <div className="card-body ">
                          <label className="text-muted d-block form-label">Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308</label>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <i className="fal fa-file-pdf"></i>
                            </div>
                            <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal2}</div>
                            <div className="flex-shrink-0">
                              <a href={File + fileNameUpdated2} target="_blank" className="btn btn-link">
                                View
                              </a>
                              <a href={File + fileNameUpdated2} target="_blank" download className="btn btn-link">
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="bg-black" style={{ backgroundColor: " rgba(0, 0, 0, 0.5)" }} />
                      <div className="card bg-secondary border-0 mb-3 p-4" id="optionCard1">
                        <div>
                          <div className="form-check mb-3 d-flex align-items-center">
                            <input className="form-check-input" type="checkbox" value="optionOne" id="optionOne" checked={!riskFinanceFee} disabled={true} />
                            <label className="form-check-label text-muted" htmlFor="optionOne" style={{ fontFamily: "Oswald,sans-serif" }}>
                              Option 1
                            </label>
                          </div>
                          <div id="optn1">
                            <p>
                              <small>Upload Proof of Railroad Protective Liability Insurance for NCRR and NSR as specified in the body of the agreement and Exhibit B, Article 9.</small>
                            </p>
                            {!riskFinanceFee && fileName3 && (
                              <div className="card bg-secondary border-0 mb-2">
                                <div className="card-body">
                                  <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>
                                  <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                      <i className="fal fa-file-pdf"></i>
                                    </div>
                                    <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal3}</div>
                                    <div className="flex-shrink-0">
                                      <a href={File + fileNameUpdated3} target="_blank" className="btn btn-link">
                                        View
                                      </a>
                                      <a href={File + fileNameUpdated3} target="_blank" download className="btn btn-link">
                                        Download
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {!riskFinanceFee && fileName4 && (
                              <div className="card bg-secondary border-0 mb-2">
                                <div className="card-body">
                                  <label className="text-muted d-block form-label">Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308</label>
                                  <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                      <i className="fal fa-file-pdf"></i>
                                    </div>
                                    <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal4}</div>
                                    <div className="flex-shrink-0">
                                      <a href={File + fileNameUpdated4} target="_blank" className="btn btn-link">
                                        View
                                      </a>
                                      <a href={File + fileNameUpdated4} target="_blank" download className="btn btn-link">
                                        Download
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card bg-secondary border-0 mb-3 p-4" id="optionCard2">
                        <div>
                          <div className="form-check mb-3 d-flex align-items-center">
                            <input className="form-check-input" type="checkbox" value="" id="flexSwitchCheckChecked" disabled={true} checked={riskFinanceFee} />
                            <label className="form-check-label text-muted" htmlFor="flexSwitchCheckChecked" style={{ fontFamily: "Oswald,sans-serif" }}>
                              Option 2
                            </label>
                          </div>
                          <div id="option2">
                            <div>
                              <p id="optn2">
                                <small>
                                  Pay ${REACT_APP_NS_risk_finance_fee_original} Risk Financing Fee to be added to NSR's Master Railroad Protective Liability Insurance coverage, as described in the
                                  attached special provision.
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer py-0 justify-content-center bg-success">
                    <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          <i className="fal fa-check"></i> {action}
                        </span>
                        <span>
                          By Applicant's on <small> {date1 ? date1.slice(0, 15) : ""}</small>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
                <CompiledPayment />

                {roleName === "applicant" ||
                roleName === "Engineering Representative" ||
                roleName === "Operating Signee" ||
                roleName === "Approving Representative" ||
                roleName === "Signing Representative" ? (
                  <div className="card mb-4 position-relative comment-card">
                    <div className="card-header  border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2 className="mb-0">Sign Agreement</h2>
                        <small className="text-muted">By Applicant</small>
                      </div>
                    </div>
                    <div className="card-body">
                      <p className="m-0 text-center">Agreement Signed.</p>
                    </div>

                    <div className="card-footer py-0 justify-content-center bg-success">
                      <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                        <span className="d-flex justify-content-between align-items-center">
                          <span>
                            <i className="fal fa-check"></i> Signed
                          </span>
                          <span>
                            By {SignerName} on <small> {SignedDate ? SignedDate : ""}</small>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                ) : null}

                <div className="card mb-4 ">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Validated Fee, Insurance and Agreement</h2>
                      <small className="text-muted">By NCRR</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <p className="m-0 text-center">Validated By NCRR</p>
                  </div>
                  <div className="card-footer py-0 justify-content-center bg-success">
                    <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          <i className="fal fa-check"></i> {action2}{" "}
                        </span>
                        <span>
                          By Justin Madigan, Engineering Rep., NCRR on <small>{date2 ? date2.slice(0, 15) : ""}</small>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>

                <div className="card mb-4 ">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Validated and Signed</h2>
                      <small className="text-muted">By V.P. Engineering, NCRR</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <p className="m-0 text-center">Validated By V.P. Engineering, NCRR</p>
                  </div>
                  <div className="card-footer py-0 justify-content-center bg-success">
                    <a href="#" className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          <i className="fal fa-check"></i> {action3}{" "}
                        </span>
                        <span>
                          By Donald Arant, V.P. Engineering, NCRR on <small>{date3 ? date3.slice(0, 15) : ""}</small>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>

                <div className="card mb-4 ">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Uploaded Letter</h2>
                      <small className="text-muted">By Norfolk Southern</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="">
                      <label className="text-muted d-block">Norfolk Southern Approval Letter Uploaded</label>
                      <div className="card bg-secondary border-0">
                        <div className="card-body p-3">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <i className="fal fa-file-pdf"></i>
                            </div>
                            <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal4}</div>
                            <div className="flex-shrink-0">
                              <a href={File + fileNameUpdated4} target="_blank" className="btn btn-link">
                                View
                              </a>
                              <a href={File + fileNameUpdated4} target="_blank" download className="btn btn-link">
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="mb-3">
                      <label className="text-muted d-block">Recent Insurance Approvals</label>
                      <div className="card bg-secondary border-0">
                        <div className="card-body p-3">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <i className="fal fa-file-pdf"></i>
                            </div>
                            <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal7}</div>
                            <div className="flex-shrink-0">
                              <a href={File + fileNameUpdated7} target="_blank" className="btn btn-link">
                                View
                              </a>
                              <a href={File + fileNameUpdated7} target="_blank" download className="btn btn-link">
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer py-0 justify-content-center bg-success">
                    <a href="#" className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          <i className="fal fa-check"></i> {action4}{" "}
                        </span>
                        <span>
                          By Norfolk Southern on <small> {date4 ? date4.slice(0, 15) : ""}</small>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>

                {OperatingSigneeaction && OperatingSigneeaction == "Validate and sign" && (
                  <div className="card mb-4 ">
                    <div className="card-header  border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2 className="mb-0">Validated and Signed</h2>
                        <small className="text-muted">By Operating Signee, Norfolk Southern</small>
                      </div>
                    </div>
                    <div className="card-body">
                      <p className="m-0 text-center">Validated By Operating Signee, Norfolk Southern</p>
                    </div>
                    <div className="card-footer py-0 justify-content-center bg-success">
                      <a href="#" className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                        <span className="d-flex justify-content-between align-items-center">
                          <span>
                            <i className="fal fa-check"></i> {OperatingSigneeaction}{" "}
                          </span>
                          <span>
                            By Operating Signee, Norfolk Southern on <small>{OperatingSigneedate ? OperatingSigneedate.slice(0, 15) : ""}</small>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                )}

                {isSalesOrderValidated === false && roleName === "Approving Representative" && (
                  <div className="card mb-4 shadow">
                    <div className="card-header  border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2 className="mb-0">Validate </h2>
                        <small className="text-muted">For NCRR</small>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="col-md-12 p-0">
                        <div className="row">
                          <div className="col-lg-12 pt-3 p-0 d-flex flex-row-reverse">
                            <div className="form-check p-0">
                              {!showAll && (
                                <div className="btn btn-link text-primary text-underline" onClick={() => setShowAll(true)}>
                                  Hide
                                </div>
                              )}
                              {showAll && (
                                <div className="btn btn-link text-primary text-underline" onClick={() => setShowAll(false)}>
                                  Show All Default Fields
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {arrayOfnesuite.map((data) => {
                          const { recordName, licenseFee, hide = false } = data;
                          let validatevalueFee = licenseFee !== license_fee;

                          if (hide) return null;

                          if (recordName === "SALESORDER" && Number(license_fee) === 0) {
                            return null;
                          }
                          return (
                            <>
                              {validatevalueFee && (
                                <>
                                  {" "}
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h3 className="pt-3">{data.header} </h3>

                                    <NetsuiteLink applicationID={ItemId} v={data} />
                                  </div>
                                  <div className="row ">
                                    {data.rows.map((datas, ind) => {
                                      let item = 0;
                                      if (datas?.itemId) {
                                        item = datas?.itemId;
                                      }
                                      let itemsLength = 0;
                                      if (netsuiteField?.[recordName]?.items) {
                                        itemsLength = netsuiteField?.[recordName]?.items?.length || 0;
                                      }
                                      return (
                                        <>
                                          {(data.defaultfinalShow === true || showAll === false) && datas.header ? <h3 class="">{datas.header}</h3> : ""}
                                          {(data.defaultfinalShow === true || showAll === false) && datas.label && item !== 0 && item <= itemsLength ? (
                                            <div class="">
                                              <h3 class="form-label">{datas.label}</h3>{" "}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {datas.columns.map((data, ind) => {
                                            let value = netsuiteField?.[recordName]?.[data.keyName] || "";
                                            if (item !== 0) {
                                              try {
                                                value = netsuiteField?.[recordName]?.items[item - 1]?.[data.keyName];
                                              } catch (error) {
                                                value = undefined;
                                              }
                                            }
                                            if (!value && item !== 0) {
                                              return null;
                                            }

                                            return (
                                              <>
                                                {(data.defaultfinalShow === true || showAll === false) && (
                                                  <div className={data.col ? data.col : "col-md-6"}>
                                                    <FieldAll data={data} handleNetSuiteChange={handleNetSuiteChange} recordName={recordName} col={datas} value={value} />
                                                  </div>
                                                )}
                                              </>
                                            );
                                          })}
                                        </>
                                      );
                                    })}
                                  </div>
                                </>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>

                    {roleName === "Approving Representative" ? (
                      <div className="card-footer justify-content-center bg-primary">
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "#5E74E4",
                          }}
                          className="d-block text-white"
                          disabled={roleName !== "Approving Representative" || validateLoading}
                          onClick={handleNetSuiteClick}
                        >
                          {validateLoading && (
                            <>
                              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                              Loading...
                            </>
                          )}
                          {!validateLoading && (
                            <>
                              Validate <i className="fal fa-chevron-right ms-2"></i>
                            </>
                          )}
                        </button>
                      </div>
                    ) : (
                      <div className="card-footer justify-content-center " style={{ backgroundColor: "rgb(233 237 241)" }}>
                        Awaiting For Ncrr
                        <i className="fal fa-chevron-right ms-2"></i>
                      </div>
                    )}
                  </div>
                )}

                {isSalesOrderValidated === false ? (
                  roleName !== "Approving Representative" && (
                    <div className="card mb-4 shadow border-primary">
                      <div className="card-header  border-bottom">
                        <div className="d-flex justify-content-between align-items-center">
                          <h2 className="mb-0">Validate</h2>
                          <small className="text-muted">For NCRR</small>
                        </div>
                      </div>
                      <div className="card-footer justify-content-center " style={{ backgroundColor: "rgb(233 237 241)" }}>
                        Awaiting For Ncrr
                        <i className="fal fa-chevron-right ms-2"></i>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="card mb-4 shadow">
                    <div className="card-header  border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2 className="mb-0">Validated</h2>
                        <small className="text-muted">For NCRR</small>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="col-md-12 p-0">
                        <div className="row">
                          <div className="col-lg-12 pt-3 p-0 d-flex flex-row-reverse">
                            <div className="form-check p-0">
                              {!showAll && (
                                <div className="btn btn-link text-primary text-underline" onClick={() => setShowAll(true)}>
                                  Hide
                                </div>
                              )}
                              {showAll && (
                                <div className="btn btn-link text-primary text-underline" onClick={() => setShowAll(false)}>
                                  Show All Default Fields
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {arrayOfnesuite.map((data) => {
                          const { recordName, licenseFee, hide = false } = data;

                          let validatevalueFee = licenseFee !== license_fee;

                          if (hide) return null;

                          if (recordName === "SALESORDER" && Number(license_fee) === 0) {
                            return null;
                          }
                          return (
                            <>
                              {validatevalueFee && (
                                <>
                                  {" "}
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h3 className="pt-3">{data.header} </h3>

                                    <NetsuiteLink applicationID={ItemId} v={data} />
                                  </div>
                                  <div className="row ">
                                    {data.rows.map((datas, ind) => {
                                      let item = 0;
                                      if (datas?.itemId) {
                                        item = datas?.itemId;
                                      }
                                      let itemsLength = 0;
                                      if (netsuiteField?.[recordName]?.items) {
                                        itemsLength = netsuiteField?.[recordName]?.items?.length || 0;
                                      }
                                      return (
                                        <>
                                          {(data.defaultfinalShow === true || showAll === false) && datas.header ? <h3 class="">{datas.header}</h3> : ""}
                                          {(data.defaultfinalShow === true || showAll === false) && datas.label && item !== 0 && item <= itemsLength ? (
                                            <div class="">
                                              <h3 class="form-label">{datas.label}</h3>{" "}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {datas.columns.map((data, ind) => {
                                            let value = netsuiteField?.[recordName]?.[data.keyName] || "";
                                            if (item !== 0) {
                                              try {
                                                value = netsuiteField?.[recordName]?.items[item - 1]?.[data.keyName];
                                              } catch (error) {
                                                value = undefined;
                                              }
                                            }
                                            if (!value && item !== 0) {
                                              return null;
                                            }

                                            return (
                                              <>
                                                {(data.defaultfinalShow === true || showAll === false) && (
                                                  <div className={data.col ? data.col : "col-md-6"}>
                                                    <FieldAll data={data} handleNetSuiteChange={handleNetSuiteChange} recordName={recordName} col={datas} value={value} />
                                                  </div>
                                                )}
                                              </>
                                            );
                                          })}
                                        </>
                                      );
                                    })}
                                  </div>
                                </>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>

                    {isSalesOrderValidated && (
                      <div className="m-2">
                        <TriggerTransactionButton role={roleName} />
                      </div>
                    )}

                    <div className="card-footer py-0 justify-content-center bg-success">
                      <a href="#" className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                        <span className="d-flex justify-content-between align-items-center">
                          <span>
                            <i className="fal fa-check"></i> {"Validated"}{" "}
                          </span>
                          <span>
                            By Ncrr on <small>{validateAdte && validateAdte}</small>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                )}

                {action5 === "Send" && roleName != "Operating Representative" ? (
                  <div className="card mb-4 shadow ">
                    <div className="card-header  border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2 className="mb-0">Final Release</h2>
                        <small className="text-muted">For HNTB</small>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="mb-3">
                        <label className="text-muted d-block">Uploaded Sealed Certification Form</label>

                        <div className="card bg-secondary border-0">
                          <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <i className="fal fa-file-pdf"></i>
                              </div>
                              <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal5}</div>
                              <div className="flex-shrink-0">
                                <a href={File + fileNameUpdated5} target="_blank" className="btn btn-link">
                                  View
                                </a>
                                <a href={File + fileNameUpdated5} target="_blank" download className="btn btn-link">
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="text-muted d-block">Uploaded Final Application Approval Letter (Release Letter)</label>

                        <div className="card bg-secondary border-0">
                          <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <i className="fal fa-file-pdf"></i>
                              </div>
                              <div className="flex-grow-1 ms-3 w-50">{fileNameOrginal6}</div>
                              <div className="flex-shrink-0">
                                <a href={File + fileNameUpdated6} target="_blank" className="btn btn-link">
                                  View
                                </a>

                                <a href={File + fileNameUpdated6} target="_blank" className="btn btn-link">
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <>
                      <div className="card-footer justify-content-center bg-success">
                        <div className="d-block text-white" disabled>
                          <span className="d-flex justify-content-between align-items-center">
                            <span>
                              <i className="fal fa-check"></i>
                              Send
                            </span>
                            <span>
                              <small>By HNTB {date6 ? date6.slice(0, 15) : ""}</small>
                            </span>
                          </span>
                        </div>
                      </div>
                    </>
                  </div>
                ) : (
                  roleName != "Operating Representative" &&
                  isSalesOrderValidated === true && (
                    <div className="card mb-4 border-primary shadow ">
                      <div className="card-header  border-bottom">
                        <div className="d-flex justify-content-between align-items-center">
                          <h2 className="mb-0">Final Release</h2>
                          <small className="text-muted">For HNTB</small>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="mb-3">
                          <div className="mb-3">
                            {downloadSignedDocuments ? (
                              <a
                                href={File + (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 1 && !downloadSignedDocuments
                                    ? signedDocumentsEnvelopeId.length - 1 + "_"
                                    : signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length + "_") +
                                  fileNameUpdated
                                }
                                target="_blank"
                                className="btn btn-link"
                              >
                                View Agreement
                              </a>
                            ) : (
                              <a
                                className={roleName == "Engineering Representative" && downloadSignedDocuments ? "btn btn-sm btn-success disabled" : "btn btn-sm btn-primary"}
                                disabled={roleName !== "Engineering Representative"}
                                href={`${API_URL}/eg001?applicationID=${applicationID}`}
                              >
                                {" "}
                                {downloadSignedDocuments ? "Prepare documents for final release" : "Prepare documents for final release"}
                              </a>
                            )}
                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="text-muted d-block">Upload Sealed Certification Form</label>

                          <div className="input-group mb-3">
                            <input
                              type="file"
                              className="form-control"
                              id="sealed_certification"
                              accept=".pdf"
                              {...register("sealed_certification", {
                                required: true,
                                onChange: (e) => {
                                  const fileName = e.target.files[0].name;
                                  if (!checkFileName(fileName)) {
                                    setCharacterError((data) => ({
                                      ...data,
                                      sealed_certification: true,
                                      sealed_certificationName: fileName,
                                    }));
                                  } else {
                                    setCharacterError((data) => ({
                                      ...data,
                                      sealed_certification: false,
                                      sealed_certificationName: "",
                                    }));
                                  }
                                },
                              })}
                              disabled={!downloadSignedDocuments || roleName !== "Engineering Representative"}
                            />
                            <label className="input-group-text bg-secondary" htmlFor="sealed_certification">
                              Upload
                            </label>
                          </div>
                          <span className="text-danger">
                            <span>
                              {errors.sealed_certification && errors.sealed_certification.type === "required" && <span>This field is required</span>}
                              {characterError.sealed_certification && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                            </span>
                          </span>
                        </div>
                        <div className="mb-3">
                          <label className="text-muted d-block">Upload Final Application Approval Letter (Release Letter)</label>

                          <div className="input-group mb-3">
                            <input
                              type="file"
                              accept=".pdf"
                              className="form-control"
                              id="final_application_approval_letter"
                              {...register("final_application_approval_letter", {
                                required: true,
                                onChange: (e) => {
                                  const fileName = e.target.files[0].name;
                                  if (!checkFileName(fileName)) {
                                    setCharacterError((data) => ({
                                      ...data,
                                      final_application_approval_letter: true,
                                      final_application_approval_letterName: fileName,
                                    }));
                                  } else {
                                    setCharacterError((data) => ({
                                      ...data,
                                      final_application_approval_letter: false,
                                      final_application_approval_letterName: "",
                                    }));
                                  }
                                },
                              })}
                              disabled={!downloadSignedDocuments || roleName !== "Engineering Representative"}
                            />
                            <label className="input-group-text bg-secondary" htmlFor="final_application_approval_letter">
                              Upload
                            </label>
                          </div>
                          <span className="text-danger">
                            <span>
                              {errors.final_application_approval_letter && errors.final_application_approval_letter === "required" && <span>This field is required</span>}
                              {characterError.final_application_approval_letter && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                            </span>
                          </span>
                        </div>
                      </div>

                      <>
                        {roleName !== "Engineering Representative" ? (
                          <div className="card-footer justify-content-center " style={{ backgroundColor: "rgb(233 237 241)" }}>
                            Awaiting for HNTB
                            <i className="fal fa-chevron-right ms-2"></i>
                          </div>
                        ) : (
                          <div className={downloadSignedDocuments ? "card-footer justify-content-center bg-primary" : "card-footer justify-content-center bg-primary disabled"}>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#5E74E4",
                              }}
                              className="d-block text-white"
                              disabled={!downloadSignedDocuments || roleName !== "Engineering Representative"}
                            >
                              {roleName !== "Engineering Representative" ? "Awaiting for HNTB" : "Send"}
                              <i className="fal fa-chevron-right ms-2"></i>
                            </button>
                          </div>
                        )}
                      </>
                    </div>
                  )
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
