import React, { useState } from "react";
import { File } from "../../api";
import ReactHtmlParser from "react-html-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Upload } from "antd";
import { commentsFileUpload, getCommentsDraftAgreement } from "../../api/currentApplications";
import { useUser } from "../../hooks/useUser";
import { socket } from "../../utils/socket-io";

export default function LicenseUpdateComment(props) {
  const [load, setLoad] = useState(false);
  const { roleName = "" } = useUser() || {};
  const [editorState, setEditorState] = useState("");
  const [allComments, setAllComments] = useState([]);
  let { fileNameOriginal, signedDocumentsEnvelopeId, fileNameUpdated, applicationID, qId, handleComment, putCommentEdit, setFile, resolvedValues, resolveCompileComment } = props || {};
  let allCommentsId = allComments.map((aID, i) => i);
  let allID = [...allCommentsId];
  let { id } = qId || {};
  React.useEffect(async () => {
    socket.emit("chat send");
    socket.on("chat send", (msg) => (msg.action === "Uploaded" ? setAllComments((prev) => (prev ? [...prev, msg] : [msg])) : null));
    let responseComment = await getCommentsDraftAgreement(applicationID);

    responseComment.data.data.compiled.map((value) => (value.action === "Uploaded" ? (value.comment ? setAllComments(value.comment && value.comment) : setAllComments([])) : null));
  }, []);
  let valueprops = {
    action: "",
    onChange(info) {
      const formData = new FormData();
      formData.append("applicationID", applicationID);
      if (info && info.fileList && info.fileList.length > 0) {
        info.fileList.map((fileData) => formData.append("file", fileData.originFileObj));
      }

      commentsFileUpload(formData).then((res) => {
        setFile(res.data.data);
      });
    },
    multiple: true,
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const addRefresh = () => {
    window.location.reload();
  };
  return (
    <div>
      {load && (
        <div className="d-flex align-items-center justify-content-center  display-2 file-upload-loader">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      )}
      <div
        className="modal fade comment-modaledit hide"
        id="comment-modaledit"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-modal="true"
        role="dialog"
        // style={{ display: "block" }}
      >
        <div className="modal-dialog modal-xl modal-dialog-centered mx-h-100 modal-dialog-scrollable">
          <div className="modal-content h-100">
            <div className="modal-body  p-0">
              <div className="container-fluid h-100 p-0">
                <div className="row h-100 m-0">
                  <div className="col-md-5 h-100 p-0 overflow-auto border-right">
                    <div className="card-header px-5">
                      <h3 className="m-0">Compiled License Agreement</h3>
                    </div>
                    <div className="card  border-0 p-4 ">
                      <div className="card bg-secondary border-0 mb-2">
                        <div className="card-body">
                          <label className="text-muted d-block form-label"> Uploaded License Agreement</label>
                          <div className="d-flex align-items-center mb-4">
                            <div className="flex-shrink-0">
                              <i className="fal fa-file-pdf"></i>
                            </div>
                            <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal}</div>
                            <div className="flex-shrink-0">
                              <a
                                href={File + (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 1 ? signedDocumentsEnvelopeId.length - 1 + "_" : "") + fileNameUpdated}
                                target="_blank"
                                className="btn btn-link"
                              >
                                View
                              </a>
                              <a
                                href={File + (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 1 ? signedDocumentsEnvelopeId.length - 1 + "_" : "") + fileNameUpdated}
                                target="_blank"
                                className="btn btn-link"
                              >
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 h-100 bg-secondary p-0">
                    <div className="row msg-box m-0  h-100">
                      <div className="col-md-12 p-0">
                        <div className="collapse show  h-100" id="comment2">
                          <div className="card border-0 rounded-0 chat-window bg-secondary  h-100">
                            <div className="tab-col">
                              <ul className="nav nav-tabs justify-content-between row m-0" id="myTab" role="tablist">
                                <li className="nav-item col p-0" role="presentation">
                                  <button
                                    className="nav-link active comments-head"
                                    id="team-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#team"
                                    type="button"
                                    role="tab"
                                    aria-controls="team"
                                    aria-selected="true"
                                  >
                                    New Comments
                                  </button>
                                </li>
                              </ul>

                              <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="team" role="tabpanel" aria-labelledby="team-tab">
                                  <div className="card-body h-100 overflow-auto pt-0">
                                    <div className="panel-body msg_container_base container-fluid">
                                      {allComments &&
                                        allComments.map((comme) => (
                                          <div className={`row msg_container ${JSON.parse(comme && comme.from)?.roleName === roleName ? "base_sent justify-content-end" : "base_receive"} `}>
                                            <div className="col-md-8">
                                              <div className="d-flex justify-content-end">
                                                <div className="messages">
                                                  {ReactHtmlParser(comme && comme.comment)}
                                                  {comme &&
                                                    comme.files &&
                                                    comme &&
                                                    comme.files &&
                                                    comme.files.map((rr) => (
                                                      <div className="row align-items-center mb-4">
                                                        <div className="col-7">
                                                          <div className="d-flex align-items-center">
                                                            <i className="fal fa-file-pdf"></i>
                                                            <p className="text-break ms-3 mb-0 ">{rr.originalName}</p>
                                                          </div>
                                                        </div>
                                                        <div className="col-5">
                                                          <a href={File + rr.fileNameUpdated} target="_blank" className="btn btn-sm m-0 btn-link">
                                                            View
                                                          </a>
                                                          <a href={File + rr.fileNameUpdated} target="_blank" className="btn btn-sm m-0 btn-link">
                                                            Download
                                                          </a>
                                                        </div>
                                                      </div>
                                                    ))}
                                                  <time className="text-muted">
                                                    {comme.timeStamp} {JSON.parse(comme.from)?.roleName}
                                                  </time>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {resolvedValues === true ? null : (
                              <div className="card-footer bg-secondary p-0">
                                <div className="position-relative w-100 border">
                                  <div className="">
                                    <div className="d-flex flex-column custom-editor">
                                      <CKEditor
                                        config={{
                                          toolbar: ["bold", "italic", "strike"],
                                        }}
                                        editor={ClassicEditor}
                                        data={editorState}
                                        style={{
                                          "margin-top": "100px",
                                          border: "5px solid red",
                                        }}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setEditorState(data);
                                          handleComment(data, id);
                                        }}
                                      />

                                      <button
                                        className="btn bg-primary"
                                        onClick={() => {
                                          setEditorState("");
                                          putCommentEdit();
                                        }}
                                      >
                                        <div className="send">
                                          <span className="text-white shadow">Add Comment</span>
                                        </div>
                                      </button>
                                    </div>
                                    <div className="upload-col">
                                      <>
                                        <div>
                                          <div>
                                            <Upload customRequest={dummyRequest} accept=".pdf, .cad, .docx" {...valueprops} className="upload-list-inline" id="siofu_input" name="aggrement">
                                              <a className="upload-btn text-primary pl-1">
                                                <i className="fas fa-paperclip"></i>
                                              </a>
                                            </Upload>
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between border-top ">
              <div className="row w-100 m-0">
                <div className="col-md-5 p-0"></div>
                <div className="col-md-7 p-0 justify-content-between d-flex">
                  <div className={`${resolvedValues === false ? "" : "d-flex col-md-3"}`}>
                    {(roleName === "applicant" || roleName === "Approving Representative") && resolvedValues === false && allComments && allComments.length > 0 ? (
                      <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdropEdit">
                        Resolve Comment
                      </button>
                    ) : null}

                    <>
                      {resolvedValues === true ? (
                        <button type="button" className="btn btn-success disabled" data-bs-toggle="modal" data-bs-target="#staticBackdropEdit">
                          <i className="far fa-check text-muted"></i> Resolved
                        </button>
                      ) : null}
                    </>
                  </div>
                  {resolvedValues === false && (
                    <div className=" col-md-7 p-0">
                      <span className="text-red h5">
                        Adding a comment here will not automatically send notification. Please make comment at once and click on Notify button after adding a comment.
                      </span>
                    </div>
                  )}
                  <div className="col-md-2">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={addRefresh}>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="staticBackdropEdit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="resolve1">
                Are Your Sure?
              </h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p className="text-center mb-0">This section is not reversible.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-primary" onClick={resolveCompileComment} data-bs-dismiss="modal">
                Resolve
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
