import React from "react";
import { useForm } from "react-hook-form";
import { getLicenseStatus, getFormData, getcurrentStatus, sendNotify } from "../../api/currentApplications";
import UploadDraftLoading from "../loaders/UploadDraftLoading";
import { File } from "../../api";
import toast from "react-hot-toast";
import DraftCommentComments from "./DraftCommentComments";
import { useUser } from "../../hooks/useUser";
export default function DraftAggrementFinalReview(props) {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  let { ItemId, applicationTitle, setApplicationTitle, setHNTBFileNumber, HNTBFileNumber } = props;
  const [draftValue, setDraftValue] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [stage, setStage] = React.useState("");
  const [compileValue, setCompileValue] = React.useState({});
  const [applicationsignedDocumentsEnvelopeId, setapplicationsignedDocumentsEnvelopeId] = React.useState("");

  React.useEffect(() => {
    const fetchData = async () => {
      let response = await getLicenseStatus(ItemId);
      setDraftValue(response.data.data);
      setLoading(false);
      let newRes = await getcurrentStatus(ItemId);
      setStage(newRes.data.data);

      getFormData(ItemId).then(({ data }) => {
        if (data && data.signedDocumentsEnvelopeId) {
          setapplicationsignedDocumentsEnvelopeId(data.signedDocumentsEnvelopeId);
        }
      });
      const object = {
        applicationID: ItemId,
        signerEmail: ["Test"],
      };
      let data = await sendNotify(object);
      setCompileValue(data.data.responseData);
    };
    fetchData();
  }, []);

  let draft = draftValue.draft || {};
  let draftdata = draft && draft[0];
  let { formData: formData1 } = (draft && draft[2]) || {};
  let { action, date } = draftdata || {};
  let draftdata1 = draft && draft[1];
  let { action: act1, date: dat2 } = draftdata1 || {};
  let draftLisc = draft && draft[2];
  let { licenseFee, AgreementData, supportingData } = draftdata || {};
  let license_value = licenseFee && licenseFee[0];
  let supporting_doc_value = (supportingData && supportingData[0]) || {};

  let { license_fee } = license_value || "";
  let { fileNameOriginal, fileNameUpdated } = (AgreementData && AgreementData[0]) || {};

  let { action: action1, date: date1 } = draftLisc || {};
  let activity_number = (formData1 && formData1[0]) || "";
  let ddr = (draft && draft[3]) || [];
  let { action: action2, date: date2, formData: formData2 } = ddr || {};

  const onSubmit = (d) => {
    props.draftAggrementFinalReview(d);
  };
  let ncrrNumber = (formData2 && formData2[0]) || {};
  let ncrr_fileNumber = ncrrNumber || {};
  let hntb_fileNumber = HNTBFileNumber ? HNTBFileNumber : draftValue.HNTB_File_Number || "";
  if (HNTBFileNumber) {
    setHNTBFileNumber(hntb_fileNumber);
  }

  const { roleName = "", contact = {} } = useUser() || {};

  let currentEmail = contact.email;
  const SignedDate = compileValue ? (compileValue.signedAt ? new Date(compileValue.signedAt).toString().slice(0, 15) : "") : "";
  let { name: SignerName, email: SignerEmail } = compileValue || {};
  const validateSignedDate = compileValue ? (compileValue.validatedat ? new Date(compileValue.validatedat).toString().slice(0, 15) : "") : "";

  return loading ? (
    <>
      <UploadDraftLoading />
      <UploadDraftLoading />
    </>
  ) : (
    <div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div id="files-upload">
              <div className="card mb-4 position-relative comment-card">
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Sign Application</h2>
                    <small className="text-muted">By Applicant</small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <div className="mb-2 mt-2">
                      {SignerEmail === currentEmail ? (
                        <label className="text-muted d-block form-label d-block">You are authorized to sign the application.</label>
                      ) : (
                        <label className="text-muted d-block form-label d-block">{"Application Signed"}</label>
                      )}

                      {SignerName && (
                        <>
                          <div className="alert-success alert fs-5 mt-3 mb-0 text-center">{`${SignerName} digitally Signed this Application.`}</div>
                          {/* ApplicantLegalName */}
                          {/* {(SignerEmail === currentEmail  )?<></>:<hr />} */}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-footer py-0 justify-content-center bg-success">
                  <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i> Signed
                      </span>
                      <span>
                        By {SignerName} on <small> {SignedDate ? SignedDate : ""}</small>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
              <div className="card mb-4 shadow ">
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0 ">Validate Application Signature</h2>
                    <small className="text-muted">For HNTB</small>
                  </div>
                </div>
                <div className="card-body pt-0 pb-0">
                  <div className="card bg-secondary border-0">
                    <div className="card-body p-3">
                      <div>
                        <span className="mb-0 text-muted">Application</span>
                        <a
                          // style={role==="Approving Representative" ? {}:{ pointerEvents: "none" }}
                          target="_blank"
                          href={File + (applicationsignedDocumentsEnvelopeId ? "1_" : "") + ItemId + ".pdf"}
                          className="btn btn-link"
                        >
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer py-0 justify-content-center bg-success">
                  <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i> Validated
                      </span>
                      <span>
                        By HNTB on <small>{validateSignedDate ? validateSignedDate : ""}</small>
                      </span>
                    </span>
                  </a>
                </div>
              </div>

              <div className="card mb-4 position-relative comment-card">
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Agreement Generated</h2>
                    <small className="text-muted">By HNTB</small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label className="text-muted d-block">Uploaded Agreement</label>
                      {roleName === "Engineering Representative" || roleName === "Approving Representative" ? (
                        <button className="btn-outline-success btn-sm btn text-success comment-btn position-relative text-right" type="button" data-bs-toggle="collapse" data-bs-target="#comment1">
                          <i className="far fa-check"></i> View
                        </button>
                      ) : null}
                    </div>

                    <div className="card bg-secondary border-0">
                      <div className="card-body p-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="fal fa-file-pdf"></i>
                          </div>
                          <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal}</div>
                          <div className="flex-shrink-0">
                            <a href={File + fileNameUpdated} className="btn btn-link" target="_blank">
                              View
                            </a>
                            <a href={File + fileNameUpdated} target="_blank" className="btn btn-link">
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(roleName === "Engineering Representative" || roleName === "Approving Representative") && (
                    <>
                      <div className="mb-3">
                        <label className="text-muted d-block">Supporting Document</label>

                        {supporting_doc_value.fileDataMultiple && supporting_doc_value.fileDataMultiple.length > 0 ? (
                          <>
                            {supporting_doc_value.fileDataMultiple &&
                              supporting_doc_value.fileDataMultiple.map((supporting_doc_value, ind) => (
                                <div className="card-body p-3" key={ind}>
                                  <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                      <i className="fal fa-file-pdf"></i>
                                    </div>
                                    <div className="flex-grow-1 ms-3 w-50">{supporting_doc_value.fileNameOriginal ? supporting_doc_value.fileNameOriginal : fileNameOriginal}</div>
                                    <div className="flex-shrink-0">
                                      <a href={File + (supporting_doc_value.fileNameUpdated ? supporting_doc_value.fileNameUpdated : null)} target="_blank" className="btn btn-link">
                                        View
                                      </a>
                                      <a href={File + (supporting_doc_value.fileNameUpdated ? supporting_doc_value.fileNameUpdated : null)} target="_blank" className="btn btn-link">
                                        Download
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ))}{" "}
                          </>
                        ) : (
                          <p className="h5 m-3 p-2">No Supporting Documents</p>
                        )}
                      </div>
                    </>
                  )}
                  {roleName === "Engineering Representative" || roleName === "Approving Representative" ? (
                    <div className="mb-3">
                      <label className="text-muted d-block">Entered License Fee</label>
                      <div className="card bg-secondary border-0">
                        <div className="card-body p-3">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">${license_fee}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <DraftCommentComments
                    commentview={true}
                    license_fee={license_fee}
                    fileNameOriginal={fileNameOriginal}
                    fileNameUpdated={fileNameUpdated}
                    stage={stage.currentStep}
                    supporting_doc_value={supporting_doc_value}
                  />
                </div>
                <div className="card-footer py-0 justify-content-center bg-success">
                  <a href="#" className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i>
                        {action}{" "}
                      </span>
                      <span>
                        By HNTB on <small> {date ? date.slice(0, 15) : ""}</small>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">NCRR Review Draft Agreement</h2>
                    <small className="text-muted">By NCRR</small>
                  </div>
                </div>
                <div className="card-footer py-0 justify-content-center bg-success">
                  <a href="#" className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i>
                        {act1}
                      </span>
                      <span>
                        {""} By NCRR on <small> {dat2 ? dat2.slice(0, 15) : ""}</small>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
              <div className="card mb-4 ">
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Agreement Reviewed</h2>
                    <small className="text-muted">By Norfolk Southern</small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="text-muted d-block">Entered Activity Number</label>

                    <div className="card border-0">
                      <div className=" p-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="">{activity_number ? activity_number : ""}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer py-0 justify-content-center bg-success">
                  <a href="#" className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i> {action1}{" "}
                      </span>
                      <span>
                        By Norfolk Southern on <small> {date1 ? date1.slice(0, 15) : ""}</small>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card mb-4 border-primary shadow ">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Final Review</h2>
                      {/* <small className="text-muted">For NCRR</small> */}
                      <small className="text-muted">For HNTB</small>
                    </div>
                    <hr />

                    <>
                      {action2 === "Send to HNTB" ? (
                        <>
                          <div className="mb-4">
                            {/* <label className="text-muted d-block">
                            Entered NCRR File Number
                          </label> */}
                            <label className="text-muted d-block">Entered NCRR File Number</label>
                            <div className="card bg-secondary border-0">
                              <div className="card-body p-3">
                                <div className="d-flex align-items-center">
                                  <div className="">{ncrr_fileNumber}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mb-0">
                            {/* <label className="text-muted d-block">
                          Entered NCRR File Number
                        </label> */}
                            <label className="text-muted d-block">Entered HNTB File Number</label>
                            <div className="card bg-secondary border-0">
                              <div className="card-body p-3">
                                <div className="d-flex align-items-center">
                                  <div className="">{hntb_fileNumber}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mb-3">
                            <label className="text-muted d-block">Enter NCRR File Number</label>
                            <input
                              type="text"
                              className="form-control"
                              value={applicationTitle}
                              id="ncrr_fileNumber"
                              name="ncrr_fileNumber"
                              disabled={roleName !== "Engineering Representative"}
                              onChange={(e) => {
                                setApplicationTitle(e.target.value);
                              }}
                            />
                          </div>

                          {errors.ncrr_fileNumber && <span className="text-danger">This field is required</span>}

                          <div className="mb-0">
                            <label className="text-muted d-block">Enter HNTB File Number</label>
                            <input
                              type="text"
                              className="form-control"
                              value={hntb_fileNumber}
                              id="hntb_fileNumber"
                              name="hntb_fileNumber"
                              disabled={roleName !== "Engineering Representative"}
                              onChange={(e) => {
                                setHNTBFileNumber(e.target.value);
                              }}
                            />
                          </div>
                        </>
                      )}
                    </>
                  </div>

                  {action2 === "Send to HNTB" ? (
                    <div className="card-footer justify-content-center  bg-success " style={{ color: "white" }}>
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          {" "}
                          <i className="fal fa-check"></i> Completed{" "}
                        </span>
                        <span>
                          {" "}
                          <small>By HNTB on {date2 ? date2.slice(0, 15) : ""}</small>
                        </span>
                      </span>
                    </div>
                  ) : (
                    <>
                      {roleName !== "Engineering Representative" ? (
                        <div className="card-footer justify-content-center " style={{ backgroundColor: "rgb(233 237 241)" }}>
                          Awaiting for HNTB
                          <i className="fal fa-chevron-right ms-2"></i>
                        </div>
                      ) : (
                        <div className="card-footer justify-content-center bg-primary">
                          <button
                            disabled={roleName !== "Engineering Representative"}
                            style={{
                              border: "none",
                              backgroundColor: "#5E74E4",
                            }}
                            className="d-block text-white"
                          >
                            {roleName !== "Engineering Representative" ? "Awaiting for HNTB" : "Upload and Compile Agreement"}
                            <i className="fal fa-chevron-right ms-2"></i>
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
