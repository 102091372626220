import React, { useState } from "react";
import { File, REACT_APP_NS_risk_finance_fee_original } from "../../api";
import ReactHtmlParser from "react-html-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Upload } from "antd";
import { commentsFileUpload, getCommentsDraftAgreement } from "../../api/currentApplications";
import { useUser } from "../../hooks/useUser";
import { socket } from "../../utils/socket-io";

export default function CompileComments2(props) {
  const { roleName = "" } = useUser() || {};
  const [editorState, setEditorState] = useState("");
  const [allComments, setAllComments] = useState([]);
  let {
    fileNameOriginal1,
    fileNameOriginal2,
    fileNameOriginal3,
    fileNameOriginal4,
    fileNameUpdated2,
    fileNameUpdated1,
    fileNameUpdated3,
    fileNameUpdated4,
    stage,
    applicationID,
    fileName3,
    fileName4,
    qId,
    feevalue,
    handleComment,
    putComment,
    setFile,
    resolvedValue,
    resolveCompileComment,
    UnresolveCompileComment,
  } = props || {};
  let { id } = qId || {};
  React.useEffect(() => {
    const fetchComments = async () => {
      socket.emit("chat send");
      socket.on("chat send", (msg) => (msg.action === "Sign and Paid" ? setAllComments((prev) => (prev ? [...prev, msg] : [msg])) : null));
      let responseComment = await getCommentsDraftAgreement(applicationID);

      responseComment.data.data.compiled.map((value) => (value.action === "Sign and Paid" ? (value.comment ? setAllComments(value.comment && value.comment) : setAllComments([])) : null));
    };
    fetchComments();
  }, []);
  let valueprops = {
    action: "",
    onChange(info) {
      const formData = new FormData();
      formData.append("applicationID", applicationID);
      if (info && info.fileList && info.fileList.length > 0) {
        info.fileList.map((fileData) => formData.append("file", fileData.originFileObj));
      }

      commentsFileUpload(formData).then((res) => setFile(res.data.data));
    },
    multiple: true,
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const addRefresh = () => {
    window.location.reload();
  };
  return (
    <div>
      <div
        className="modal fade comment-modal2 hide"
        id="comment-modal2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered mx-h-100 modal-dialog-scrollable">
          <div className="modal-content h-100">
            <div className="modal-body  p-0">
              <div className="container-fluid h-100 p-0">
                <div className="row h-100 m-0">
                  <div className="col-md-5 h-100 p-0 overflow-auto">
                    <div className="card-header px-5">
                      <h3 className="m-0">Insurance Uploaded</h3>
                    </div>
                    <div className="card  border-0 h-100">
                      <div className="card-body">
                        <div className="container-fluid p-0">
                          <div className="row">
                            <div className="card-body">
                              <div className="mb-3">
                                <div className="card bg-secondary border-0 mb-2">
                                  <div className="card-body">
                                    <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <i className="fal fa-file-pdf"></i>
                                      </div>
                                      <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal1}</div>
                                      <div className="flex-shrink-0">
                                        <a href={File + fileNameUpdated1} target="_blank" className="btn btn-link" rel="noreferrer">
                                          View
                                        </a>
                                        <a href={File + fileNameUpdated1} target="_blank" download className="btn btn-link" rel="noreferrer">
                                          Download
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card bg-secondary border-0 mb-2">
                                  <div className="card-body ">
                                    <label className="text-muted d-block form-label">Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308</label>
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <i className="fal fa-file-pdf"></i>
                                      </div>
                                      <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal2}</div>
                                      <div className="flex-shrink-0">
                                        <a href={File + fileNameUpdated2} target="_blank" className="btn btn-link" rel="noreferrer">
                                          View
                                        </a>
                                        <a href={File + fileNameUpdated2} target="_blank" download className="btn btn-link" rel="noreferrer">
                                          Download
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <hr
                                    className="bg-black"
                                    style={{
                                      backgroundColor: " rgba(0, 0, 0, 0.5)",
                                    }}
                                  />
                                </div>
                                <div className="card bg-secondary border-0 mb-3 p-4" id="optionCard1">
                                  <div>
                                    <div className="form-check mb-3 d-flex align-items-center">
                                      <input className="form-check-input" type="checkbox" value="optionOne" id="optionOne" checked={fileName3 && fileName4 ? true : false} disabled={true} />
                                      <label
                                        className="form-check-label text-muted"
                                        htmlFor="optionOne"
                                        style={{
                                          fontFamily: "Oswald,sans-serif",
                                        }}
                                      >
                                        Option 1
                                      </label>
                                    </div>
                                    <div id="optn1">
                                      <p>
                                        <small>Upload Proof of Railroad Protective Liability Insurance for NCRR and NSR as specified in the body of the agreement and Exhibit B, Article 9.</small>
                                      </p>
                                      {fileName3 && (
                                        <div className="card bg-secondary border-0 mb-2">
                                          <div className="card-body">
                                            <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>
                                            <div className="d-flex align-items-center">
                                              <div className="flex-shrink-0">
                                                <i className="fal fa-file-pdf"></i>
                                              </div>
                                              <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal3}</div>
                                              <div className="flex-shrink-0">
                                                <a href={File + fileNameUpdated3} target="_blank" className="btn btn-link" rel="noreferrer">
                                                  View
                                                </a>
                                                <a href={File + fileNameUpdated3} target="_blank" download className="btn btn-link" rel="noreferrer">
                                                  Download
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      {fileName4 && (
                                        <div className="card bg-secondary border-0 mb-2">
                                          <div className="card-body">
                                            <label className="text-muted d-block form-label">
                                              Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308
                                            </label>
                                            <div className="d-flex align-items-center">
                                              <div className="flex-shrink-0">
                                                <i className="fal fa-file-pdf"></i>
                                              </div>
                                              <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal4}</div>
                                              <div className="flex-shrink-0">
                                                <a href={File + fileNameUpdated4} target="_blank" className="btn btn-link" rel="noreferrer">
                                                  View
                                                </a>
                                                <a href={File + fileNameUpdated4} target="_blank" download className="btn btn-link" rel="noreferrer">
                                                  Download
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card bg-secondary border-0 mb-3 p-4" id="optionCard2">
                                  <div>
                                    <div className="form-check mb-3 d-flex align-items-center">
                                      <input className="form-check-input" type="checkbox" value="" id="flexSwitchCheckChecked" disabled={true} checked={feevalue === true ? true : false} />
                                      <label
                                        className="form-check-label text-muted"
                                        htmlFor="flexSwitchCheckChecked"
                                        style={{
                                          fontFamily: "Oswald,sans-serif",
                                        }}
                                      >
                                        Option 2
                                      </label>
                                    </div>
                                    <div id="option2">
                                      <div>
                                        <p id="optn2">
                                          <small>
                                            Pay ${REACT_APP_NS_risk_finance_fee_original} Risk Financing Fee to be added to NSR's Master Railroad Protective Liability Insurance coverage, as described
                                            in the attached special provision.
                                          </small>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 h-100 bg-secondary p-0">
                    <div className="row msg-box m-0  h-100">
                      <div className="col-md-12 p-0">
                        <div className="collapse show  h-100" id="comment2">
                          <div className="card border-0 rounded-0 chat-window bg-secondary  h-100">
                            <div className="tab-col">
                              <ul className="nav nav-tabs justify-content-between row m-0" id="myTab" role="tablist">
                                <li className="nav-item col p-0" role="presentation">
                                  <button
                                    className="nav-link active comments-head"
                                    id="team-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#team"
                                    type="button"
                                    role="tab"
                                    aria-controls="team"
                                    aria-selected="true"
                                  >
                                    New Comments
                                  </button>
                                </li>
                              </ul>

                              <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="team" role="tabpanel" aria-labelledby="team-tab">
                                  <div className={`card-body h-100 overflow-auto pt-0 ${roleName !== "Engineering Representative" ? "" : "pb-0"}`}>
                                    <div className="panel-body msg_container_base container-fluid">
                                      {allComments &&
                                        allComments.map((comme) => (
                                          <div className={`row msg_container ${JSON.parse(comme && comme.from)?.roleName === roleName ? "base_sent justify-content-end" : "base_receive"} `}>
                                            <div className="col-md-8">
                                              <div className="d-flex justify-content-end">
                                                <div className="messages">
                                                  {ReactHtmlParser(comme && comme.comment)}
                                                  {comme &&
                                                    comme.files &&
                                                    comme &&
                                                    comme.files &&
                                                    comme.files.map((rr) => (
                                                      <div className="row align-items-center mb-4">
                                                        <div className="col-7">
                                                          <div className="d-flex align-items-center">
                                                            <i className="fal fa-file-pdf"></i>
                                                            <p className="text-break ms-3 mb-0 ">{rr.originalName}</p>
                                                          </div>
                                                        </div>
                                                        <div className="col-5">
                                                          <a href={File + rr.fileNameUpdated} target="_blank" className="btn btn-sm m-0 btn-link" rel="noreferrer">
                                                            View
                                                          </a>
                                                          <a href={File + rr.fileNameUpdated} target="_blank" className="btn btn-sm m-0 btn-link" rel="noreferrer">
                                                            Download
                                                          </a>
                                                        </div>
                                                      </div>
                                                    ))}
                                                  <time className="text-muted">
                                                    {comme.timeStamp} {JSON.parse(comme.from)?.roleName}
                                                  </time>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {stage >= 12
                              ? null
                              : roleName !== "Engineering Representative" && (
                                  <div className="card-footer bg-secondary p-0">
                                    <div className="position-relative w-100 border">
                                      <div className="">
                                        <div
                                          // onSubmit={() => { putComment() }}
                                          className="d-flex flex-column custom-editor"
                                        >
                                          <CKEditor
                                            config={{
                                              toolbar: ["bold", "italic", "strike"],
                                            }}
                                            editor={ClassicEditor}
                                            data={editorState}
                                            style={{
                                              "margin-top": "100px",
                                              border: "5px solid red",
                                            }}
                                            onChange={(event, editor) => {
                                              const data = editor.getData();
                                              setEditorState(data);
                                              handleComment(data, id);
                                            }}
                                          />

                                          <button
                                            className="btn bg-primary"
                                            onClick={() => {
                                              setEditorState("");
                                              putComment();
                                            }}
                                          >
                                            <div className="send">
                                              <span className="text-white shadow">Add Comment</span>
                                            </div>
                                          </button>
                                        </div>
                                        <div className="upload-col">
                                          <>
                                            <div>
                                              <div>
                                                <Upload customRequest={dummyRequest} accept=".pdf, .cad, .docx" {...valueprops} className="upload-list-inline" id="siofu_input" name="aggrement">
                                                  <a className="upload-btn text-primary pl-1">
                                                    <i className="fas fa-paperclip"></i>
                                                  </a>
                                                </Upload>
                                              </div>
                                            </div>
                                          </>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between border-top ">
              <div className="row w-100 m-0">
                <div className="col-md-5 p-0"></div>
                <div className="col-md-7 p-0 justify-content-between d-flex">
                  <div className={`${resolvedValue === false ? "" : "d-flex col-md-3"}`}>
                    {roleName === "Approving Representative" && resolvedValue === false && allComments && allComments.length > 0 ? (
                      <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        Resolve Comment
                      </button>
                    ) : null}
                    {roleName === "Approving Representative" && resolvedValue === true ? (
                      <button type="button" disabled={stage >= 12} onClick={UnresolveCompileComment} className="btn btn-primary">
                        Unresolve Comment
                      </button>
                    ) : null}

                    <>
                      {resolvedValue === true ? (
                        <button type="button" className="btn btn-primary disabled" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                          <i className="far fa-check text-muted"></i> Resolved
                        </button>
                      ) : null}
                    </>
                  </div>
                  {resolvedValue === false
                    ? roleName !== "Engineering Representative" && (
                        <div className=" col-md-7 p-0">
                          <span className="text-red h5">
                            Adding a comment here will not automatically send notification. Please make comment at once and click on Notify button after adding a comment.
                          </span>
                        </div>
                      )
                    : null}
                  <div className="col-md-2">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={addRefresh}>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="resolve1">
                Are Your Sure?
              </h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p className="text-center mb-0">This section is not reversible.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-primary" onClick={resolveCompileComment} data-bs-dismiss="modal">
                Resolve
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
